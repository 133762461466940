/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/

/*
    Created on : November 16, 2016, 12:20:37 PM
    Author     : Saimoon Durrani
*/

* {
  margin: 0px;
  padding: 0px;
}

html,
body {
  color: #666666;
  /*color: #0080ff;*/
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  background: #ffffff;
}

/* body {
  padding-bottom: 60px;
} */

img,
a {
  border: none;
  outline: none !important;
}

.max-width {
  width: 100%;
}

.img-responsive {
  width: 100%;
}

.padd-zero {
  padding-left: 0px;
  padding-right: 0px;
}

.wrapper {
  max-width: 1160px;
  margin: 0 auto;
}

.headder_sticky .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.main_header {
  padding-top: 15px;
  padding-bottom: 12px;
}

 {
  vertical-align: top;
}

.dlogo {
  float: left;
  margin-right: 19px;
}

.errorMessage {
  font-weight: 300 !important;
  font-size: small;
  text-align: left;
}

.main_nav ul {
  padding: 0 0 0;
  margin: 0 0 0;
}

.main_nav ul li {
  padding: 11px 3px 0;
  margin: 0;
  line-height: inherit;
  background: none;
  list-style: none;
  display: inline-block;
}

.main_nav ul li a {
  font-weight: normal;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  /*color: #0080ff;*/
  text-transform: capitalize;
  text-decoration: none;
  display: block;
  padding: 6px 10px;
}

.main_nav ul li a:hover,
.main_nav ul li a:focus,
.main_nav ul li.active a {
  background: #0080ff;
  /*color: #0080ff;*/
  color: #ffffff;
  border-radius: 3px;
}

.main_nav ul li ul {
  padding: 0 0 0;
  margin: 0 0 0;
  float: none;
}

.main_nav ul li ul li {
  list-style: none;
  background: none;
  display: block;
  margin: 0 0 0;
  padding: 0 0 0;
  float: none;
  border-radius: 0;
}

.main_nav ul li ul li a {
  border-radius: 0;
  font-size: 14px;
  color: #000000;
  /*color: #0080ff;*/
  font-weight: normal;
  display: block;
  vertical-align: top;
}

.main_nav ul li.active ul li a,
.main_nav ul li:focus ul li a,
.main_nav ul li:hover ul li a {
  border-radius: 0;
}

.main_nav ul li ul li a .washicon {
  background: url(../images/machine_icon.png) no-repeat top;
  width: 14px;
  height: 16px;
  vertical-align: top;
  display: inline-block;
  margin-right: 7px;
  margin-top: 2px;
}

.main_nav ul li ul li a i {
  margin-right: 7px;
}

.main_nav ul li ul li a:hover .washicon,
.main_nav ul li ul li.active a .washicon,
.main_nav ul li ul li:focus a .washicon {
  background-position: bottom;
}

.heading_bar {
  background: #0080ff;
  padding: 18px 0 24px;
  text-align: center;
}

.selectedTime {
  font-size: 12px !important;
}

.heading_bar h2 {
  font-size: 32px;
  color: #ffffff;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 0;
}

.progress_container {
  padding-top: 22px;
}

.progress_status {
  margin-bottom: 35px;
}

.progress_status h2 {
  font-size: 20px;
  color: #666666;
  /*color: #0080ff;*/
  font-weight: normal;
  line-height: inherit;
  margin: 0 0 0;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

/*Progress styles*/

#progress_section {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

/*progressbar*/

#progressbar {
  margin-bottom: 50px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: #333333;
  text-transform: capitalize;
  font-size: 15px;
  width: 33%;
  float: left;
  position: relative;
  font-family: "Open Sans", sans-serif;
}

#progressbar li a {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

#progressbar li a:hover,
#progressbar li a:focus {
  text-decoration: none;
}

#progressbar li:before {
  content: open-quote;
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  /* color: #333; */
  /* background: white; */
  border-radius: 50%;
  margin: 0 auto 5px auto;
  /* border: 1px solid #000; */
  position: inherit;
  z-index: 2;
}

/*progressbar connectors*/

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #0080ff;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/

/*The number of the step and the connector before it = green*/

#progressbar li.active:before,
#progressbar li.active:after {
  background: #0080ff;
  color: #0080ff;
  /*color: #0080ff;*/
}

#progressbar li.completed:before,
#progressbar li.completed:after {
  /*background: #f37b21;*/
  background: #0080ff;
  /*color: #f37b21;*/
  color: #0080ff;
}

#progressbar li.completed a {
  color: #0080ff;
}

#progressbar li.active a {
  color: #0080ff;
}

input.form-control,
select.form-control {
  background: #ededed;
  padding: 14px 20px;
  font-size: 18px;
  color: #818181;
  font-family: "Open Sans", sans-serif;
  height: auto;
  border: #f4f4f4 1px solid;
}

textarea.form-control {
  background: #ededed;
  padding: 14px 20px;
  font-size: 18px;
  color: #818181;
  font-family: "Open Sans", sans-serif;
  height: auto;
  border: #f4f4f4 1px solid;
  resize: none;
  min-height: 140px;
}

.item_insertion {
  padding-bottom: 50px;
}

.item_insertion .checkbox {
  float: right;
  margin: 0 0 0 42px;
  font-size: 16px;
  color: #666666;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  padding: 16px 0;
}

.item_insertion .checkbox input[type="checkbox"] {
  margin: 5px 0 0 -20px;
}

.margin-bottom-30 {
  margin-bottom: 28px;
}

.btn {
  /*background: #999999;*/
  background: #0080ff;
  color: #fff;
  font-size: 17px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  padding: 8px 20px;
  border: 0;
  outline: 0;
}

.btn:hover {
  /*background: #0d8fcc;*/
  background: #0080ff;
  color: #fff;
}
/*.btn:focus {
        color: #FFF;
    }*/

.btn-primary-s {
  /*background: #f5871b;*/
  background: #0080ff;
  color: #fff;
  font-size: 17px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  padding: 8px 25px;
  border: 0;
  outline: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #333333;
  clear: both;
  padding: 11px 0;
  text-align: center;
}

.footer p {
  margin: 0;
  line-height: inherit;
  font-size: 12px;
  color: #cccccc;
}

.navigation_button {
  display: none;
}

.paddin_left {
  padding-left: 0px;
  padding-right: 10px;
  margin-bottom: 18px;
}

.paddin_right {
  padding-right: 10px;
  padding-left: 0;
  margin-bottom: 18px;
}

.acnhor {
  font-size: 17px;
  /*color: #0d8fcc;*/
  background: #0080ff;
  text-decoration: underline;
}

.btn-secondary {
  /*background: #0d8fcc;*/
  background: #0080ff;
  font-weight: normal;
  padding: 15px 20px;
  margin-top: 1px;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

#singin .modal-dialog,
#forget .modal-dialog {
  max-width: 434px;
}

.modal .modal-dialog .modal-header {
  text-align: center;
  padding: 17px 15px;
  border-bottom: 2px solid #a0a0a0;
}

.modal-header .close {
  margin-top: -21px;
  padding: 0 0;
  line-height: inherit;
  font-size: 28px;
  height: auto;
  margin-right: -7px;
  outline: 0;
  /*color: #999999;*/
  color: #0080ff;
  opacity: 1;
}

.modal-header .close:focus {
  outline: 0;
}

.modal .modal-dialog .modal-header h4 {
  font-size: 22px;
  /*color: #333333;*/
  color: #0080ff;
  line-height: inherit;
  margin: 0 0 0;
  font-weight: 600;
}

.modal .modal-dialog .modal-body {
  position: relative;
  text-align: center;
  padding: 15px 23px;
}

.modal .modal-dialog .modal-body a {
  font-size: 16px;
  /*color: #0d8fcc;*/
  color: #0080ff;
  margin-bottom: 12px;
  display: inline-block;
}

.modal .modal-dialog .modal-body input.form-control {
  font-size: 16px;
  font-weight: normal;
  /*color: #818181;*/
  color: #0080ff;
  padding: 12px 14px;
  margin-bottom: 16px;
  text-align: left;
}

.btn-green {
  font-size: 18px;
  color: #fff;
  background: #0080ff;
  outline: 0;
  padding: 11px 10px;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-body .btn-green {
  display: block;
  width: 100%;
}

.modal_wrap {
  display: table;
  width: 453px;
  height: 100%;
  margin: 0 auto;
}

.modal_wrap .modal_center {
  display: table-cell;
  vertical-align: middle;
}

.order_summary {
  padding-top: 35px;
  margin-bottom: 10px;
}

.order_s_colum {
  padding: 0 10px;
}

.zero-left-padding {
  padding-left: 0;
}

.zero-right-padding {
  padding-right: 0;
}

.order_colum {
  background: #ededed;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 14px 15px;
  min-height: 100px;
  margin-bottom: 20px;
}

.order_colum h4 {
  margin: 0 0 0;
  font-size: 20px;
  color: #666666;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  padding: 0 0 0 0;
  position: relative;
}

.order_colum h4 span {
  display: block;
  font-weight: 600;
  font-size: 17px;
  color: #0d8fcc;
  padding-top: 1px;
  padding-left: 53px;
}

.order_colum h4 span small {
  font-weight: normal;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #0d8fcc;
}

.order_colum h4 i {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}

.order_colum h4 i.ico_pickup {
  width: 40px;
  height: 26px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: left 0;
}

.order_colum h4 i.ico_delivery {
  width: 40px;
  height: 26px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -43px 0;
}

.order_colum h4 i.ico_items {
  width: 23px;
  height: 40px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -86px 0;
  margin-top: -6px;
  position: relative;
}

.order_colum h4 i.ico_crease {
  width: 22px;
  height: 40px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -112px 0;
  margin-top: -2px;
  position: relative;
}

.order_colum h4 i.ico_starch {
  width: 36px;
  height: 40px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -136px 0;
  margin-top: -5px;
  position: relative;
}

.order_colum h4 i.ico_instruc {
  width: 32px;
  height: 26px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -175px 0;
  margin-top: -0px;
  position: relative;
}

.order_colum h4 i.ico_address {
  width: 20px;
  height: 26px;
  background: url(../images/order_sprites.png) no-repeat;
  background-position: -209px 0;
  margin-top: -0px;
  position: relative;
  word-wrap: break-word;
}

.order_colum p {
  margin: 5px 0 0;
  font-size: 16px;
  color: #0d8fcc;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  word-wrap: break-word;
}

.order_colum h6 {
  margin: 10px 0 0;
  font-size: 19px;
  color: #0d8fcc;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  word-wrap: break-word;
}

.qty_col {
  text-align: center;
  padding-bottom: 13px;
}

.qty_col span {
  font-weight: 600;
  font-size: 17px;
  color: #0d8fcc;
  padding-top: 0;
  display: block;
  font-family: "Open Sans", sans-serif;
}

.confirmation_box {
  margin-bottom: 28px;
  clear: both;
}

.confirmation_box input[type="checkbox"] {
  vertical-align: top;
  margin-right: 10px;
}

.confirmation_box label {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}

.confirmation_box label a {
  color: #0d8fcc;
  text-decoration: none;
}

.order_s_colum:nth-child(1) {
  padding-left: 0;
}

.order_s_colum:nth-child(5) {
  padding-right: 0;
}

.calender-r-padding {
  padding-right: 30px;
  padding-left: 0;
}

.calender-l-padding {
  padding-left: 30px;
  padding-right: 0;
}

.schedule_box {
}

.schedule_box ul {
  list-style: none;
  padding: 0 0 0;
  margin: 0 0 0;
  overflow: hidden;
}

.schedule_box ul li {
  list-style: none;
  line-height: inherit;
  padding: 0 0 0;
}

.schedule_box .schedule_days ul {
  margin-bottom: 8px;
}

.schedule_box .schedule_days ul li {
  float: left;
  width: 14.2%;
  box-sizing: border-box;
  padding-right: 4px;
  padding-bottom: 4px;
}

.schedule_box h2 {
  color: #333333;
  font-size: 22px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: inherit;
  margin: 0 0 17px;
}

.schedule_box h2 span {
  font-weight: normal;
  font-size: 14px;
  float: right;
  padding: 7px 10px 0 0;
}

.schedule_box .schedule_days ul li a {
  background: #dddddd;
  display: block;
  text-align: center;
  font-size: 14px;
  /*color: #0d8fcc;*/
  color: #0080ff;
  font-weight: 600;
  border-radius: 4px;
  padding: 13px 0 10px;
  text-decoration: none;
}

.schedule_box .schedule_days ul li a:hover,
.schedule_box .schedule_days ul li.active a,
.schedule_box .schedule_days ul li.ui-tabs-active a {
  text-decoration: none;
  /*background: #0d8fcc;*/
  background: #0080ff;
  color: #fff;
}

.schedule_box .schedule_days ul li a span {
  font-size: 24px;
  display: block;
}

.schedule_time ul {
  margin: 0 0 0;
  padding: 0 0 0;
  list-style: none;
}

.schedule_time ul li {
  list-style: none;
  background: none;
  float: left;
  width: 50%;
  padding: 0 6px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.schedule_time ul li:first-child {
  padding-left: 0;
}

.schedule_time ul li:nth-child(3),
.schedule_time ul li:nth-child(5),
.schedule_time ul li:nth-child(7),
.schedule_time ul li:nth-child(9) {
  padding-left: 0;
}

.schedule_time ul li a {
  display: block;
  text-align: center;
  color: #000000;
  border-radius: 6px;
  padding: 6px 0 7px;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  border: #bcbcbc 2px solid;
}

.schedule_time ul li a:hover,
.schedule_time ul li.active a {
  /*background: #0d8fcc;*/
  background: #0080ff;
  border: #0d8fcc 2px solid;
  color: #fff;
}

.schedule_time ul li a:hover i.arrow_time {
  background-position: bottom;
}

.schedule_time ul li i.arrow_time {
  display: inline-block;
  background: url(../images/time_arrow.png) no-repeat top;
  width: 30px;
  height: 10px;
  margin: 0 7px 0;
}

.green_schedule .schedule_days ul li a {
  color: #3dc04f;
}

.green_schedule .schedule_days ul li a:hover,
.green_schedule .schedule_days ul li.active a,
.green_schedule .schedule_days ul li.ui-tabs-active a {
  background: #3dc04f;
}

.schedule_time ul li.active i.arrow_time {
  background-position: bottom;
}

.green_schedule .schedule_time ul li a:hover,
.green_schedule .schedule_time ul li.active a,
.green_schedule .schedule_time ul li.ui-tabs-active a {
  background: #3dc04f;
  border: #3dc04f 2px solid;
}

.order_title {
  max-width: 360px;
  margin: 30px auto 0;
  position: relative;
}

.order_title h3 {
  margin: 0 0 0;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.order_title h3 span {
  color: #666666;
}

.order_title a.order_prev {
  display: inline-block;
  text-decoration: none;
  background: url(../images/order_next_prev.png) no-repeat left top;
  width: 11px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
}

.order_title a.order_next {
  display: inline-block;
  text-decoration: none;
  background: url(../images/order_next_prev.png) no-repeat right top;
  width: 11px;
  height: 17px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
}

.order_detail {
  margin: 0 auto 38px;
  max-width: 798px;
  overflow: hidden;
}

.pickup_time {
  float: left;
  padding: 4px 0 0 43px;
  background: url(../images/ico_return.png) no-repeat left top;
}

.pickup_time.active {
  background: url(../images/ico_ok.png) no-repeat left top;
}

.order_detail .pickup_time h5 {
  text-align: right;
}

.order_detail h5 {
  font-size: 16px;
  color: #666666;
  font-weight: normal;
  margin: 0 0 0;
}

.order_detail h5 span {
  color: #333333;
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding-top: 8px;
}

.return_time {
  float: right;
  padding: 4px 43px 0 0;
  background: url(../images/ico_return.png) no-repeat right top;
}

.return_time.active {
  background: url(../images/ico_ok.png) no-repeat right top;
}

.order_detail .return_time h5 {
  text-align: right;
}

.blue-btn {
  background: #0d8fcc;
}

/*Progress Circle*/

.progress_circle {
  background-color: transparent;
  height: 392px;
  width: 392px;
  margin: 50px auto 0;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  border-left-color: #c8c8c8;
  border-top-color: #c8c8c8;
  border-bottom-color: #c8c8c8;
  border-right-color: #c8c8c8;
  border-left-style: dotted;
  border-top-style: dotted;
  border-bottom-style: dotted;
  border-right-style: dotted;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
}

.progress_circle0 {
  background-color: transparent;
  height: 392px;
  width: 392px;
  margin: 50px auto 0;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  border-left-color: #c8c8c8;
  border-top-color: #c8c8c8;
  border-bottom-color: #c8c8c8;
  border-right-color: #c8c8c8;
  border-left-style: dotted;
  border-top-style: dotted;
  border-bottom-style: dotted;
  border-right-style: dotted;
  transform: rotate(-45deg);
  position: relative;
}

.progress_circle25 {
  background-color: transparent;
  height: 392px;
  width: 392px;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  border-left-color: #c8c8c8;
  border-top-color: #c8c8c8;
  border-bottom-color: #c8c8c8;
  border-left-style: dotted;
  border-top-style: dotted;
  border-bottom-style: dotted;
  position: relative;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 50px auto 0;
}

.progress_circle50 {
  background-color: transparent;
  height: 392px;
  width: 392px;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  border-left-color: #c8c8c8;
  border-top-color: #c8c8c8;
  border-left-style: dotted;
  border-top-style: dotted;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  margin: 50px auto 0;
}

.progress_circle75 {
  background-color: transparent;
  height: 392px;
  width: 392px;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  border-top-color: #c8c8c8;
  border-top-style: dotted;
  position: relative;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 50px auto 0;
}

.progress_circle100 {
  background-color: transparent;
  height: 392px;
  width: 392px;
  display: block;
  border-radius: 50%;
  border: 3px solid #2f9ed2;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  margin: 50px auto 0;
}

.progress_circle img,
.progress_circle0 img,
.progress_circle25 img,
.progress_circle50 img,
.progress_circle75 img,
.progress_circle100 img {
  margin-top: 7px;
}

.status_home {
  text-align: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-color: #2f9ed2;
  border: 5px solid #fff;
  position: absolute;
  z-index: 2;
  right: 31px;
  top: 27px;
  margin-left: -27px;
}

.status_pickup {
  text-align: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-color: #c8c8c8;
  border: 5px solid #fff;
  position: absolute;
  z-index: 2;
  right: 30px;
  bottom: 29px;
}

.status_pickup img {
  margin-top: 11px;
  margin-left: 1px;
}

.status_wash {
  text-align: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-color: #c8c8c8;
  border: 5px solid #fff;
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 29px;
}

.status_wash img {
  margin-top: 8px;
}

.status_hang {
  text-align: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-color: #c8c8c8;
  border: 5px solid #fff;
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 29px;
}

.status_hang img {
  margin-top: 3px;
}

.progress_circle25 .status_pickup,
.progress_circle50 .status_pickup,
.progress_circle50 .status_wash,
.progress_circle75 .status_pickup,
.progress_circle75 .status_wash,
.progress_circle75 .status_hang,
.progress_circle100 .status_pickup,
.progress_circle100 .status_wash,
.progress_circle100 .status_hang {
  background: #2f9ed2;
}

.Progress_title {
  width: 392px;
  height: 392px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  padding-top: 50%;
}

.Progress_title h1 {
  color: #333333;
  font-size: 67px;
  font-weight: 300;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  margin: -54px 0 0;
  text-align: center;
}

.Progress_title h1 span {
  display: block;
  font-size: 32px;
  font-weight: normal;
  color: #f67717;
  text-transform: uppercase;
  padding-top: 14px;
}

/*Progress Circle ends*/

/* .home_sticky {
  padding-bottom: 39px;
} */

.headder_sticky {
}
.img_width {
  width: 100%;
}

.banner_full {
  position: relative;
}

.banner_full img {
  width: 100%;
}

.banner_full .banner_layer {
  position: absolute;
  width: 440px;
  right: 13%;
  bottom: 19%;
}

.banner_full .banner_layer .btn_order {
  border: 3px solid #ffffff;
  border-radius: 29px;
}

.banner_full .banner_layer h2 {
  font-weight: bold;
  font-size: 34px;
  color: #2c2c2c;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.banner_full .banner_layer h2 span {
  font-size: 26px;
  color: #2c2c2c;
  display: block;
  padding-top: 8px;
  font-weight: bold;
}

.banner_full .banner_layer .app_buttons {
  width: 100%;
  padding-left: 17px;
  padding-top: 10px;
}

.banner_full .banner_layer .app_buttons h6 {
  font-size: 22px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  color: #333333;
  padding-left: 37px;
  margin-bottom: 20px;
}

.banner_full .banner_layer .app_buttons a {
  margin: 0 5px;
}

.schedule_time ul li.disable_schedule a {
  pointer-events: none;
  background: #f0f0f0;
  color: #c9c9c9;
  border: #c9c9c9 2px solid;
}

/*About Section*/

.about_section {
  padding: 73px 0 70px;
}

.about_section .about_detail {
  padding-left: 5px;
  padding-top: 85px;
}

.about_section .about_detail h1 {
  font-size: 45px;
  color: #333333;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  line-height: 54px;
  text-transform: capitalize;
  margin: 0 0 15px;
}

.about_section .about_detail h1:before {
  display: block;
  background: #0d8fcc;
  height: 4px;
  width: 30%;
  content: "";
  margin-bottom: 25px;
}

.about_section .about_detail p {
  line-height: 30px;
  color: #333333;
  font-size: 20px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

/*About Section ends*/

/*Order Process Section*/

.order_process {
  background: url(../images/order_proccess_bg.jpg) no-repeat center fixed;
  background-size: cover;
  padding-top: 115px;
  padding-bottom: 70px;
}

.order_process .order_process_colum {
  padding: 0 46px;
  position: relative;
  text-align: center;
}

.colum_row {
  margin: 0 -46px;
}

.process_circle {
  /* width: 100%;*/
  width: 220px;
  min-height: 220px;
  border-radius: 50%;
  border: #fff 3px solid;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  margin-bottom: 22px;
}

.order_ico {
  display: block;
  width: 97px;
  height: 125px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -64px;
  margin-left: -43px;
  background: url(../images/sprites.png) no-repeat left top;
}

.pick_ico {
  display: block;
  width: 90px;
  height: 101px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -45px;
  background: url(../images/sprites.png) no-repeat -113px 0;
}

.wash_ico {
  display: block;
  width: 91px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -60px;
  margin-left: -45px;
  background: url(../images/sprites.png) no-repeat -216px 0;
}

.delvi_ico {
  display: block;
  width: 129px;
  height: 83px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -41px;
  margin-left: -65px;
  background: url(../images/sprites.png) no-repeat top right;
}

.order_process .order_process_colum:after {
  content: "";
  width: 99px;
  height: 20px;
  background: url(../images/process_after.png) no-repeat;
  position: absolute;
  right: -50px;
  top: -5px;
}

.order_process .order_process_colum:last-child:after {
  display: none;
}

.order_process .order_process_colum h2 {
  font-size: 38px;
  font-weight: normal;
  color: #ffffff;
  margin: 0 0 19px;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
}

.order_process .order_process_colum p {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  margin: 0 0 0;
}

/*Order Process Section ends*/

/*Customers Section*/

.customers_section {
  background: #fff;
  padding: 50px 0 50px;
}

.customers_section h3 {
  font-size: 44px;
  color: #333333;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  position: relative;
  text-align: center;
  margin: 0 0 65px;
}

.customers_section h3:after {
  display: block;
  height: 5px;
  width: 85px;
  background: #0080ff;
  content: "";
  margin: 23px auto 0;
}

.customer_colum {
  padding: 0 45px 0;
  text-align: center;
}

.opening_time {
  display: block;
  background: url(../images/time-bg.png) no-repeat;
  width: 87px;
  height: 110px;
  margin: 0 auto 14px;
}

.ico_trustworthy {
  display: block;
  background: url(../images/trust-icon.png) no-repeat;
  width: 90px;
  height: 110px;
  margin: 0 auto 14px;
}

.ico_guaranteed {
  display: block;
  background: url(../images/guaranty-icon.png) no-repeat;
  width: 91px;
  height: 113px;
  margin: 0 auto 14px;
}

.customer_colum h4 {
  font-size: 35px;
  color: #333333;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 14px;
}

.customer_colum p {
  line-height: 30px;
  font-size: 24px;
  color: #666666;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

/*Customers Section ends*/

/*Customers Testimonials*/

.customers_testimonials {
  padding: 45px 0 60px;
  /*background: url(../images/testimonial_bg.jpg) no-repeat center fixed #012637;*/
  background: url(../images/pattern-bg.jpg) no-repeat center fixed #03374d;
  background-size: cover;
}

.customers_testimonials h3 {
  font-size: 44px;
  color: #ffffff;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  position: relative;
  text-align: center;
  margin: 0 0 55px;
}

.customers_testimonials h3:after {
  display: block;
  height: 5px;
  width: 85px;
  background: #ffffff;
  content: "";
  margin: 23px auto 0;
}

.testimonial_colum {
  padding: 0 23px;
}

.testimonial_colum img {
  float: left;
  margin-right: 7px;
}

.testimonial_colum p {
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
  margin: 0 0 14px;
}

.testimonial_colum h5 {
  font-size: 21px;
  color: #ffffff;
  font-weight: normal;
  margin: 0 0 0;
}

.testimonial_colum h5 span {
  display: block;
  font-style: italic;
  font-size: 18px;
  color: #ffffff;
  margin-top: 10px;
}

/*Customers Testimonials ends*/

/*Apps Download*/

.appdownload_section {
  background: url(../images/app_image.jpg) no-repeat center fixed;
  background-size: cover;
}

.appdownload_section .wrapper {
  position: relative;
  min-height: 450px;
}

.appdownload_section .app_buttons {
  width: 283px;
  position: absolute;
  right: 10%;
  bottom: 45px;
  text-align: center;
}

.appdownload_section .app_buttons h6 {
  font-size: 22px;
  font-weight: normal;
  color: #333333;
}

.appdownload_section .app_buttons a {
  margin: 0 5px;
}

.ico_ios {
  display: inline-block;
  width: 129px;
  height: 38px;
  background: url(../images/sprites.png) no-repeat right bottom -75px;
}

.ico_android {
  display: inline-block;
  width: 129px;
  height: 38px;
  background: url(../images/sprites.png) no-repeat right bottom;
}

/*Apps Download ends*/

.order_section {
  background: #fff;
  padding: 55px 0 55px;
  text-align: center;
}

.order_section h4 {
  font-size: 35px;
  color: #333333;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 15px;
}
.white_txt {
  color: ghostwhite;
}

.btn_order {
  font-size: 25px;
  font-weight: normal;
  color: #fff;
  background: #f6871b;
  font-family: "Open Sans", sans-serif;
  padding: 10px 68px;
  text-transform: uppercase;
  margin: 10px 0 0;
  clear: both;
  display: inline-block;
  border-radius: 26px;
  text-decoration: none;
}

.btn_order:hover,
.btn_order:focus {
  text-decoration: none;
  color: #fff;
}

.top_footer {
  background: #000000;
  padding: 34px 0 34px;
}
/**************** 29-4-2019 ***********************/
.top-header {
  width: 100%;
  background: #0080ff;
  height: 38px;
}

.top-header .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.top-header .col-sm-8 {
  padding-left: 0;
}

.top-header .col-sm-4 {
  padding-right: 0;
}

.top-header ul li {
  color: #fff;
  font-weight: bold;
  font-size: 11.9px;
}

.top-header ul li a {
  color: #fff;
  font-weight: bold;
  font-size: 11.9px;
}

.acount-info .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

.acount-info .dropdown.bootstrap-select {
  background: none;
  /*color:#fff;*/
  font-size: 12px;
  border-radius: 0;
  outline: none;
  border: none;
}

.acount-info .bootstrap-select > select {
  outline: none;
  border: none;
}

.acount-info .dropdown.bootstrap-select .btn {
  background: none !important;
  border: none;
  border: none;
  color: #fff;
  font-size: 14px;
  border-radius: 0;
  outline: none;
}

.acount-info .dropdown-toggle::after,
.acount-info .dropdown-toggle.btn::after {
  display: none;
}

.acount-info .bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: 5px;
}

.acount-info .bootstrap-select .dropdown-menu li a {
  color: #000;
}

.acount-info .dropdown-menu > .active > a,
.acount-info .dropdown-menu > .active > a:hover,
.acount-info .dropdown-menu > .active > a:focus {
  background: #0080ff;
  color: #fff;
}

.acount-info .bootstrap-select .dropdown-menu li.active a {
  color: #fff;
  outline: none;
}

.acount-info .dropdown-menu.open {
  position: relative;
}

.acount-info .bootstrap-select > .dropdown-toggle.bs-placeholder,
.acount-info .bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.acount-info .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.acount-info .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #fff;
}

/* .manage-header {
  background: #e5e4e9;
  margin-left: -20px;
  margin-right: -20px;
} */

.acount-info .loginspan {
  padding: 10px 0 0 0;
  float: right;
  color: #fff;
}

.acount-info a.dropdown-toggle {
  color: #fff;
  padding: 0 !important;
}

.acount-info .loginspan.open {
  color: #fff !important;
}

.acount-info .dropdown-menu {
  top: 36px;
  right: 0;
  left: auto;
  border-radius: 0 0 0;
  border: none;
  box-shadow: 0 0;
}

.acount-info .dropdown-menu li a {
  color: #000;
}

.acount-info .dropdown-menu li a i {
  margin-right: 5px;
}

.customer-section {
  width: 100%;
  margin-bottom: 5px;
}

.customer-section:after {
  clear: both;
  display: block;
  content: "";
}

.customer-section .thumbnail-cst {
  width: 29%;
  float: left;
  padding: 0;
}

.customer-section .cst-info {
  width: 71%;
  float: right;
  padding: 0;
}

.customer-section .cst-info p {
  font-size: 14px;
  color: #fff;
}

.customers_testimonials h4 {
  font-size: 21px;
  color: #fff;
}

.customers_testimonials h4,
.customers_testimonials p {
  color: #fff;
  font-size: 17px;
}

.banner-pickwash {
  padding: 0;
  position: relative;
}

.banner-pickwash:after {
  clear: both;
  display: block;
  content: "";
}

.inner-banner-section {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
}

.banner-pickwash img {
  width: 100%;
}

.pos-text {
  width: 35%;
  float: right;
  padding-top: 35%;
}

.pos-text h4 {
  font-size: 36px;
  color: #2c2c2c;
  font-weight: bold;
  text-transform: uppercase;
}

.pos-text p {
  font-size: 26px;
  color: #2c2c2c;
  font-weight: bold;
  text-transform: uppercase;
}

/**************** end 29-4-2019 ***********************/
.footer_menu {
}

.footer_menu ul {
  padding: 0 0 0;
  margin: 0 0 0;
  background: none;
  list-style: none;
  overflow: hidden;
}

.footer_menu ul li {
  list-style: none;
  float: left;
  padding: 5px 13px 5px;
  margin: 0 0 0;
  background: none;
}

.footer_menu ul li:first-child {
  padding-left: 0;
}

.footer_menu ul li a {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}

.ico_twitter {
  width: 31px;
  height: 31px;
  display: inline-block;
  background: url(../images/social_icons.png) no-repeat left;
  margin: 0 6px;
  vertical-align: top;
}

.ico_linkedin {
  width: 32px;
  height: 31px;
  display: inline-block;
  background: url(../images/social_icons.png) no-repeat -43px 0;
  margin: 0 6px;
  vertical-align: top;
}

.ico_facebook {
  width: 31px;
  height: 31px;
  display: inline-block;
  background: url(../images/social_icons.png) no-repeat right;
  margin: 0 6px;
  vertical-align: top;
}

.login_header {
  float: right;
  padding-top: 8px;
}

.login_header p {
  margin: 0 0 0;
  font-size: 14px;
  color: #666666;
  display: inline-block;
  vertical-align: top;
  padding: 8px 0 0;
}

.login_header p a {
  color: #0d8fcc;
  text-decoration: underline;
}

.btn_ord {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  background: #3dc04f;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

.app_overlay {
  display: none;
}

/*19-12-2016 feedback css*/

.feedback textarea {
  resize: none;
  width: 100%;
  height: 118px;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 12px 12px;
  border: 0;
}

.feedback .fa-star {
  color: #ed9700;
  height: 31px;
  width: 31px;
}

.stars {
  margin: 10px 0 14px;
}

/*19-12-2016 order history css*/

.order_history .search {
  padding: 8px 13px;
  background-color: #f3f3f3;
  border: 1px solid #cccccc;
  font-size: 14px;
  color: #333333;
}

.search_form button {
  position: absolute;
  background-color: transparent;
  color: #9c9c9c;
  border: 0;
  top: 8px;
  right: 15px;
}

.search_form button:focus,
.search_form button:hover {
  outline: 0;
}

.search_form {
  position: relative;
  margin: 18px 0 18px;
}

.search_form .fa-search {
  font-size: 1.6em;
}

.orderHistoryTable {
  max-height: 460px;
  overflow-y: auto;
}

.orderHistoryTable thead {
  background-color: #e3e3e3;
}

.orderHistoryTable th {
  font-size: 14.66px;
  text-transform: capitalize;
  font-weight: normal;
  color: #000;
}

.orderHistoryTable {
  border: 1px solid #e2e9ee;
  border-radius: 4px;
}

.orderHistoryTable table {
  margin: 0;
}

.orderHistoryTable .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9fafb;
}

.orderHistoryTable .table-striped > tbody > tr:nth-of-type(even) {
  background-color: #ffffff;
}

.orderHistoryTable .blueText {
  color: #2f9ed2;
}

.orderHistoryTable .greenText {
  color: #2fb210;
}

.orderHistoryTable .redText {
  color: #d70000;
}

.orderHistoryTable .orangeText {
  color: #eb8a00;
}

.edit_profile {
  padding-left: 0;
  padding-right: 0;
}

/*20-12-2016 Edit Profile css*/

.edit_profile {
  padding-top: 30px;
}

.edit_profile > div:nth-child(3).paddin_right .input-group input {
  border-radius: 4px;
}

.edit_profile > div:nth-child(3) .input-group-btn .btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.edit_profile > div:nth-last-child(2) {
  padding-top: 13px;
}

.edit_profile > div:nth-last-child(2) label,
.edit_profile > div:nth-last-child(2) span {
  font-size: 18px;
  color: #666666;
  font-weight: normal;
}

.edit_profile > div:nth-last-child(2) label {
  color: #333333;
}

.edit_profile > div:nth-last-child(2) input[type="radio"] {
  display: none;
}

.edit_profile > div:nth-last-child(2) input[type="radio"] + span {
  background-image: url(../images/radio_button.png);
  display: inline-block;
  float: left;
  width: 21px;
  height: 21px;
  margin: 3px 7px 0 8px;
  position: relative;
  -webkit-appearance: none;
}

.edit_profile > div:nth-last-child(2) input[type="radio"]:checked + span {
  background-image: url(../images/radio_selected.png);
}

.edit_profile > div:nth-child(3) .input-group input {
  display: block;
  position: relative;
}

.edit_profile > div:nth-child(3) .input-group-btn .btn {
  margin-left: 6px;
}

/*angular floating label*/

.container {
  padding-top: 4em;
}

.show-hide.ng-hide-add,
.show-hide.ng-hide-remove {
  transition: all linear 0.2s;
  display: block !important;
  position: absolute;
}

.show-hide.ng-hide-add.ng-hide-add-active,
.show-hide.ng-hide-remove {
  top: 10px;
  display: block !important;
  position: absolute;
  opacity: 0;
}

.show-hide.ng-hide-add,
.show-hide.ng-hide-remove.ng-hide-remove-active {
  top: -14px;
  opacity: 1;
  display: block !important;
  position: absolute;
}

.field {
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

/* .field > label {
  position: absolute;
  top: 2px;
  left: 22px;
  font-size: 11px;
  color: #818181;
  font-weight: normal;
} */

/* .field:nth-child(even) > label {
  left: 31px;
} */



.short-input {
  max-width: 400px;
  display: block;
}

.edit_profile > div.paddin_right:nth-child(3) {
  margin-bottom: 0;
}

.edit_profile > div.paddin_right:nth-child(3) .field > label {
  z-index: 12;
}

/* 20-12-2016 profile */
.profile {
  padding-top: 35px;
}

.profile .btn-primary-s {
  background-color: #0080ff;
  color: #fff;
  margin: 0 auto;
  display: block;
  padding: 8px 16px;
}

.profile .leftProfileContainer {
  max-width: 233px;
  /*    width:100%;*/
  width: 20%;
  float: left;
}

.profile .rightProfileContainer {
  max-width: 927px;
  width: 79.91379%;
  float: left;
}

.profile .leftProfileContainer > div {
  margin-top: 14px;
}

.profile .rightProfileContainer > div {
  padding: 14px 20px;
  background-color: #f3f3f3;
}

.rightProfileContainer ul {
  list-style-type: none;
  clear: left;
}

.rightProfileContainer li {
  float: left;
  width: 33.3333%;
  margin-bottom: 16px;
}

.rightProfileContainer li h4 {
  color: #2f9ed2;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 7px;
}

.rightProfileContainer li span {
  color: #333333;
  font-size: 16px;
}

.rightProfileContainer > div:first-child h2,
.rightProfileContainer > h2 {
  color: #333333;
  font-weight: 600;
  font-size: 22px;
}

.rightProfileContainer > h2 {
  margin-bottom: 12px;
}

.rightProfileContainer > div:first-child h2 {
  margin-top: 10px;
  float: left;
}

.rightProfileContainer > div h3 {
  color: #000;
  font-size: 18px;
  margin: 2px 0 0;
  font-weight: 500;
  float: left;
  word-wrap: break-word;
}

.rightProfileContainer > .address_bar {
  margin-bottom: 4px;
}

.rightProfileContainer > .address_bar .delete_address {
  display: block;
  width: 28px;
  height: 28px;
  border: #cc0000 2px solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #cc0000;
  float: right;
}

.rightProfileContainer > .address_bar label,
.rightProfileContainer > .address_bar span,
.rightProfileContainer > .address_bar label,
.rightProfileContainer > .address_bar span {
  font-size: 18px;
  color: #666666;
  font-weight: normal;
}

.rightProfileContainer > .address_bar label,
.rightProfileContainer > .address_bar label {
  color: #333333;
}

.rightProfileContainer > .address_bar input[type="radio"],
.rightProfileContainer > .address_bar input[type="radio"] {
  display: none;
}

.rightProfileContainer .fa-pencil {
  color: #40ac4f;
  font-size: 2.2em;
  vertical-align: top;
  margin: 0 20px 0 35px;
}

.rightProfileContainer .fa-close {
  color: #cc0000;
  font-size: 1.8em;
}

.rightProfileContainer .fa-close,
.rightProfileContainer .fa-circle-o {
  color: #cc0000;
}

.rightProfileContainer .fa-close {
  font-size: 1.2em;
  /* padding-left: 6px;
  padding-top: 5px; */
  padding: 6px;
  border: 1px solid red;
  border-radius: 3px;
}

.rightProfileContainer .fa-circle-o {
  font-size: 2.2em;
  position: absolute;
}

.rightProfileContainer > .address_bar input[type="radio"] + span,
.rightProfileContainer > .address_bar input[type="radio"] + span {
  background-image: url(../images/radio_button.png);
  display: inline-block;
  float: left;
  width: 21px;
  height: 21px;
  margin: 3px 7px 0 8px;
  position: relative;
  -webkit-appearance: none;
}

.rightProfileContainer > .address_bar input[type="radio"]:checked + span,
.rightProfileContainer > .address_bar input[type="radio"]:checked + span {
  background-image: url(../images/radio_selected.png);
}

.rightFloatedDiv > .address_bar {
  display: inline-block;
  width: 24.5px;
}

.rightFloatedDiv {
  float: right;
}

.rightFloatedDiv a:hover,
.rightFloatedDiv a:focus {
  color: transparent;
}

.rightProfileContainer .edit_button {
  background-color: #0080ff;
  color: #fff;
  float: right;
  padding: 8px 25px;
}

.leftProfileContainer img + a > span {
  line-height: 41px;
  text-align: left;
  background-color: #0080ff;
  color: #fff;
  width: 100%;
  font-size: 15px;
  margin: 0;
  padding-left: 15px;
  display: block;
}

.leftProfileContainer img + a {
  display: block;
  opacity: 0;
  /*    position: absolute;*/
  float: left;
  width: 100%;
  max-width: inherit;
  transition: all 0.5s linear;
  z-index: -1;
  margin-top: -41px;
  transform: translateY(100%);
}

.leftProfileContainer img + a:hover {
  text-decoration: none;
}

.leftProfileContainer .fa-camera {
  font-size: 1.4em;
  margin-right: 4px;
}

.leftProfileContainer img:hover + a,
.leftProfileContainer img + a:hover {
  transform: translateY(0%);
  opacity: 1;
  z-index: 0;
}

.verift_btn {
  font-size: 13px;
  padding: 18px 20px;
}

.btn-secondary:hover,
.btn-secondary:focus {
  outline: 0;
}

.change_password {
  padding-top: 45px;
  max-width: 600px;
}

.change_password .field > label {
  top: 4px;
  left: 35px;
}

.change_password .btn-primary-s {
  margin-right: 15px;
  background-color: #0080ff;
  background: #0080ff;
}

.change_password .checkbox {
  margin: -12px 0 0;
}

.change_password .checkbox label {
  font-size: 15px;
  color: #333333;
  font-weight: normal;
  margin: 0 0 0;
}

.progress_circle0 .status_home {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.progress_circle0 .status_home img {
}

.progress_circle .status_home {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.progress_circle .status_home img {
}

.progress_circle25 .status_pickup {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.progress_circle50 .status_wash {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.progress_circle75 .status_hang {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.progress_circle100 .status_home {
  animation: pulsate 0.8s infinite;
  -webkit-animation: pulsate 0.8s infinite;
}

.status_pickup img,
.status_home img,
.status_hang img,
.status_wash img {
  transform: rotate(45deg);
}

.status_home img {
  margin-left: 2px;
  margin-top: 9px;
}

.order_login_div {
  float: right;
  padding: 10px 0 0;
}

.green-btn {
  background: #FDB338;
}

.order_login_div .loginspan {
  display: inline-block;
  margin: 0 8px 0 0;
  font-size: 14px;
  color: #666666;
  vertical-align: top;
  padding: 7px 0 0;
  position: relative;
}

.order_login_div .loginspan button {
  border: 0;
  background: none;
  color: #0d8fcc;
  text-decoration: none;
}

.order_login_div .loginspan a i {
  color: #000;
}

.order_login_div .loginspan ul {
  margin: 0 0 0;
  padding: 0 0 0;
  left: inherit;
  right: 0;
  top: 35px;
}

.order_login_div .loginspan ul li {
  list-style: none;
  background: none;
  line-height: inherit;
  padding: 0 1px 0;
  margin: 0 0 0;
}

.order_login_div .loginspan ul li a {
  display: block;
  padding: 4px 10px 4px;
}

.order_login_div .loginspan ul li:not(:first-child) a {
  border-top: 1px solid #ccc;
}

.order_login_div .loginspan ul li a i {
  margin-right: 7px;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1);
  }
}

.items_wrap {
  padding-top: 35px;
}

.items_wrap .nav-tabs > li > a {
  font-weight: normal;
  color: #0d8fcc;
  font-size: 16px;
  padding: 9px 32px;
}

.items_wrap .nav-tabs > li.active > a,
.items_wrap .nav-tabs > li.active > a:hover,
.items_wrap .nav-tabs > li.active > a:focus {
  background: #0d8fcc;
  color: #fff;
  border: #0d8fcc 1px solid;
}

.items_wrap .nav-tabs > li > a:hover {
  border-color: #eee #eee #0d8fcc;
}

.items_wrap .nav-tabs {
  border-bottom: 1px solid #0d8fcc;
}

.items_wrap .tab-content > .tab-pane {
  border: 1px solid #0d8fcc;
  min-height: 430px;
  border-top: 0;
}

.items_list table {
  width: 100%;
}

.items_list table thead {
  background: #f3f3f3;
}

.items_list table thead tr th {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  padding: 10px 10px 10px;
}

.items_list table tbody tr td {
  font-weight: normal;
  font-size: 14px;
  color: #0d8fcc;
  font-family: "Open Sans", sans-serif;
  padding: 10px 10px 10px;
  border-bottom: #999999 1px solid;
}

.items_list table tbody tr td button {
  width: 13px;
  height: 13px;
  border: 0;
  background: #0d8fcc;
  color: #fff;
  padding: 0 0 0;
  font-family: "Open Sans", sans-serif;
  font-size: 9px;
  line-height: 13px;
  margin: 4px 4px 0;
  vertical-align: top;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.items_list table tbody tr td input {
  width: 25px;
  padding: 0px 5px;
  font-size: 13px;
  color: #333333;
  text-align: center;
  height: 18px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: #999999 1px solid;
  font-family: "Open Sans", sans-serif;
}

.items_list table tbody tr td label {
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  min-width: 53px;
  font-family: "Open Sans", sans-serif;
}

.items_list table tbody tr:last-child td {
  border-bottom: 0;
}

.price_finalize {
  padding-top: 42px;
}

.price_estimator {
  background: #0d8fcc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.price_estimator h4 {
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 12px;
  padding: 8px 18px;
  border-bottom: #fff 1px solid;
}

.price_estimator h5 {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 3px;
  padding: 0 18px 0;
}

.price_estimator ul {
  padding: 0 18px 14px;
  margin: 0 0 0;
  list-style: none;
}

.price_estimator ul li {
  list-style: none;
  padding: 3px 0;
  margin: 0 0 0;
  line-height: inherit;
  background: none;
  font-size: 14px;
  color: #ffffff;
  clear: both;
  font-weight: normal;
}

.price_estimator ul li label {
  float: right;
  font-weight: normal;
  font-size: 14px;
}

.price_estimator ul li label span {
  display: inline-block;
  min-width: 40px;
}

.price_estimator h6 {
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 0;
  padding: 10px 18px;
  border-top: #fff 1px solid;
}

.price_estimator h6 label {
  float: right;
  font-weight: normal;
}

.price_finalize .btn {
  display: block;
  font-size: 22px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px 20px;
}

/*Feed Back*/
.feedbackrow {
  padding-top: 20px;
}

.feedbackrow h5 {
  margin: 0 0 30px;
  padding: 0 0 0;
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  line-height: 39px;
}

.feedbackrow h5 i {
  font-size: 39px;
  margin-right: 4px;
  color: #0d8fcc;
  vertical-align: top;
}

.feedbackbtn {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.feedback textarea {
  resize: none;
  width: 100%;
  height: 118px;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 12px 12px;
  border: 0;
}

.feedback .fa-star {
  color: #ed9700;
  height: 31px;
  width: 31px;
}

.stars {
  margin: 10px 0 14px;
}

.heading_banner {
  margin-bottom: 15px;
}

.heading_banner img {
  width: 100%;
}

.faq_wrap {
  padding-top: 20px;
}

.faq_wrap h3 {
  font-size: 44px;
  color: #333333;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  position: relative;
  text-align: center;
  margin: 0 0 40px;
}

.faq_wrap h3:after {
  display: block;
  height: 5px;
  width: 85px;
  background: #0d8fcc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.faq_wrap .nav-tabs > li > a {
  font-weight: normal;
  color: #0d8fcc;
  font-size: 14px;
  padding: 9px 20px;
}

.faq_wrap .nav-tabs {
  border-bottom: 1px solid #999999;
}

.faq_wrap .nav-tabs > li.active > a,
.faq_wrap .nav-tabs > li.active > a:hover,
.faq_wrap .nav-tabs > li.active > a:focus {
  background: #ffffff;
  color: #000000;
  border: #999999 1px solid;
  border-bottom: #ffffff 1px solid;
}

.faq_wrap .nav-tabs > li > a:hover {
  border-color: #eee #eee #999999;
}

.faq_wrap .tab-content > .tab-pane {
  border: 0;
  min-height: 430px;
  border-top: 0;
  padding-top: 30px;
}

.faq_q {
  background: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0 0 2px;
}

.faq_q.fq {
  background: #e6e6e6;
}

.faq_q p {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  margin: 0 0 0;
}

.faq_q p span {
  font-weight: bold;
}

.testimonials_posts {
  padding: 0 0 0;
}

.testimonial_post {
  padding: 0 12px 0;
  margin-bottom: 24px;
}

.testimonial_post img {
  float: left;
  margin-right: 8px;
  margin-bottom: 10px;
}

.testimonial_post p {
  font-size: 14px;
  line-height: 23px;
  color: #000000;
  margin: 0 0 15px;
}

.testimonial_post h5 {
  font-weight: normal;
  font-size: 21px;
  color: #000000;
  margin: 0 0 18px;
  clear: both;
}

.testimonial_post h5 span {
  display: block;
  font-weight: normal;
  font-size: 17px;
  font-style: italic;
  color: #000000;
  padding-top: 8px;
}

.testimonials_bg {
  background: #e6e6e6;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 20px 20px;
}

.rating_starts {
  padding: 0 0 0;
  margin: 0 0 0;
  background: none;
  list-style: none;
}

.rating_starts li {
  list-style: none;
  padding: 0 0 0;
  margin: 0 3px 0;
  background: none;
  float: left;
}

.rating_starts li a {
  display: block;
  background: url(../images/rating_star.png) no-repeat top;
  display: block;
  width: 25px;
  height: 26px;
  margin: 0 0 0;
}

.rating_starts li a:hover,
.rating_starts li.active a {
  background-position: bottom;
}

.how_we_works {
  background: none;
  padding-top: 50px;
}

.order_process.how_we_works .order_process_colum:after {
  background: url(../images/work_after.png) no-repeat;
}

.order_process.how_we_works .order_process_colum h2 {
  color: #000;
}

.order_process.how_we_works .order_process_colum p {
  color: #000;
}

.order_process.how_we_works .order_process_colum:nth-child(1) .process_circle {
  background: #0d8fcc;
  border-color: #0d8fcc;
}

.order_process.how_we_works .order_process_colum:nth-child(2) .process_circle {
  background: #2b288b;
  border-color: #2b288b;
}

.order_process.how_we_works .order_process_colum:nth-child(3) .process_circle {
  background: #693fbe;
  border-color: #693fbe;
}

.order_process.how_we_works .order_process_colum:nth-child(4) .process_circle {
  background: #0d55cc;
  border-color: #0d55cc;
}

.left_field {
  padding-left: 0;
  padding-right: 10px;
}

.right_field {
  padding-right: 0;
  padding-left: 10px;
  text-align: left;
}

.content_wrap {
}

.content_wrap p {
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  color: #000000;
  margin: 0 0 30px;
}

.map_preview {
  width: 100%;
  height: 400px;
}

.left_zero {
  padding-left: 0;
}

.right_zero {
  padding-right: 0;
}

.contact_wrap {
}

.contact_wrap h5 {
  font-size: 18px;
  font-weight: normal;
  color: #000;
  padding: 0 0 14px;
  margin: 0 0 10px;
  border-bottom: #cccccc 1px solid;
}

.contact_wrap h6 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  padding: 0 0 0;
  margin: 0 0 10px;
}

.contact_wrap ul {
  padding: 0 0 0;
  margin: 0 0 0;
  list-style: none;
  background: none;
}

.contact_wrap ul li {
  list-style: none;
  padding: 8px 0 8px;
  margin: 0 0 0;
  font-weight: normal;
  font-size: 12px;
  color: #666666;
}

.contact_wrap ul li a {
  color: #0694e6;
}

.contact_wrap ul li:first-child i {
  color: #4faf0f;
  font-size: 24px;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -3px;
}

.contact_wrap ul li:nth-child(2) i {
  color: #4faf0f;
  font-size: 20px;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -3px;
}

.contact_wrap ul li:nth-child(3) i {
  color: #4faf0f;
  font-size: 20px;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -1px;
}

.contact_wrap ul li:nth-child(4) i {
  color: #4faf0f;
  font-size: 20px;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -1px;
}

.social_icons {
  padding-top: 10px;
}

.social_icons a {
  display: block;
  float: left;
  width: 31px;
  height: 31px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #3a589b;
  margin: 0 9px 0 0;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.social_icons a i {
  font-size: 20px;
  margin-top: 6px;
}

.social_icons a:nth-child(2) {
  background: #1cb7eb;
}

.social_icons a:nth-child(3) {
  background: #007ab9;
}

.social_icons a:nth-child(3) i {
  margin-top: 7px;
  font-size: 18px;
}

.contact_wrap input,
.contact_wrap select {
  font-size: 16px;
  font-weight: normal;
  color: #818181;
  padding: 12px 14px;
  margin-bottom: 10px;
  text-align: left;
  border: 0;
}

.contact_wrap textarea {
  font-size: 16px;
  font-weight: normal;
  color: #818181;
  padding: 12px 14px;
  margin-bottom: 10px;
  text-align: left;
  border: 0;
  resize: none;
  min-height: 100px;
}

.contact_wrap .btn {
  color: #fff;
  padding: 8px 30px;
}

.items_list table tbody tr td label.qtyvalue {
  color: #f5871b;
  height: 21px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  padding: 0 0 0;
  margin: 0 0 0;
  min-width: 25px;
  font-size: 13px;
}

.price_estimator table {
  margin: 0 0 14px;
  width: 100%;
}

.price_estimator table tr td {
  list-style: none;
  padding: 3px 0;
  margin: 0 0 0;
  line-height: inherit;
  background: none;
  font-size: 14px;
  color: #ffffff;
  clear: both;
  font-weight: normal;
  vertical-align: top;
}

.price_estimator table tr td:first-child {
  padding: 0 10px 0 20px;
}

.price_estimator table tr td:last-child {
  padding: 0 20px 0 10px;
  text-align: right;
}

.price_list {
  max-height: 340px;
  min-height: 340px;
  overflow-y: auto;
}

.block-ui-message {
  /* background-color: #1e90ff; */
}

#uploadBtn {
  display: none;
}

.main_nav ul li .dropdown-toggle:after,
.main_nav ul li .dropdown-toggle.btn:after {
  display: none;
}

.main_nav ul li a.dropdown-toggle {
  padding-right: 10px !important;
}

.order_login_div .loginspan a.dropdown-toggle {
  padding-right: 0 !important;
}

.order_login_div .dropdown-toggle:after,
.order_login_div .dropdown-toggle.btn:after {
  display: none;
}

.faq_wrap .tab-pane {
  padding-right: 0;
  padding-left: 0;
}

.faq_wrap .tab-content {
  border: 0;
  border-top-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  padding: 0 0 0;
  background: #fff;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .modal-header {
  text-align: center;
  padding: 17px 15px;
  border-bottom: 2px solid #a0a0a0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .modal-body {
  position: relative;
  text-align: center;
  padding: 15px 23px;
}

.ngdialog.ngdialog-theme-default
  .ngdialog-content
  .modal-body
  input.form-control,
.ngdialog.ngdialog-theme-default
  .ngdialog-content
  .modal-body
  select.form-control {
  font-size: 16px;
  font-weight: normal;
  color: #818181;
  padding: 12px 14px;
  margin-bottom: 8px;
  text-align: left;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .modal-body a {
  font-size: 16px;
  color: #0d8fcc;
  margin-bottom: 12px;
  display: inline-block;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .modal-body label.has-error {
  text-align: left;
  color: red;
  display: block;
  margin-bottom: 0;
}

.main_nav ul li ul.dropdown-menu {
  top: 43px;
  left: inherit;
  right: 0;
}

.oder_select_colum .btn {
  margin-top: 7px;
}

.open {
  color: #1ca800 !important;
}

.pickedup {
  color: #0f40cd !important;
}

.pickfromstore {
  color: #b002b6 !important;
}

.deliveredtostore {
  color: #e37b00 !important;
}

.readyonstore {
  color: #00b5c5 !important;
}

.invoicecreated {
  color: #00b5c5 !important;
}

.scannedbag {
  color: #00b5c5 !important;
}

.deliveredcustomer {
  color: #32cd32 !important;
}

.pickedupstore {
  color: #3cb371 !important;
}

.cancel {
  color: #ff0000 !important;
}

.feedbackheading h4 {
  font-size: 22px;
  color: #333333;
  line-height: inherit;
  margin: 0 0 0;
  font-weight: 600;
}

.feedback .btn {
  display: block;
  width: 100%;
}

.change_password .field > label.has-error {
  top: -16px;
  left: 15px;
}

.field > label.has-error {
  top: -16px;
  left: 2px;
}

.rightProfileContainer > div h3 {
  padding: 0 0;
}

.schedule_box .schedule_days ul li.disable_date a {
  pointer-events: none;
  background: #f0f0f0;
  color: #c9c9c9;
  border: #c9c9c9 2px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 9px;
  padding-bottom: 9px;
}

.schedule_box .schedule_days ul li:focus {
  outline: 0;
}

/*For Google Maps*/
.angular-google-map-container {
  height: 400px;
}

@media all and (max-width: 1400px) {
  .pos-text {
    width: 35%;
    padding-top: 25%;
  }

  .pos-text h4 {
    font-size: 32px;
  }

  .pos-text p {
    font-size: 24px;
  }
}

@media all and (max-width: 1100px) {
  .pos-text {
    width: 36%;
    padding-top: 19%;
  }

  .pos-text h4 {
    font-size: 24px;
  }

  .pos-text p {
    font-size: 20px;
  }
}

@media all and (max-width: 900px) {
  .pos-text {
    width: 35%;
    padding-top: 15%;
  }

  .pos-text h4 {
    font-size: 20px;
  }

  .pos-text p {
    font-size: 16px;
  }
}

@media all and (max-width: 650px) {
  .pos-text {
    width: 35%;
    padding-top: 15%;
  }

  .pos-text h4 {
    font-size: 16px;
  }

  .pos-text p {
    font-size: 14px;
  }
}

@media all and (max-width: 450px) {
  .customer-section .thumbnail-cst {
    width: 35%;
  }

  .customer-section .cst-info {
    width: 65%;
  }

  .customer-section .cst-info p {
    font-size: 13px;
  }

  .pos-text {
    width: 35%;
    padding-top: 11%;
  }

  .pos-text h4 {
    font-size: 14px;
  }

  .pos-text p {
    font-size: 12px;
  }
}

@media all and (max-width: 350px) {
  .pos-text {
    width: 37.5%;
  }

  .pos-text h4 {
    font-size: 13px;
  }

  .pos-text p {
    font-size: 11px;
  }
}
/** input button country code*/
form.example .dhobi_input {
  float: left;
  width: 100%;
}

/* form.example .country_code {
  float: left;
  width: 15%;
  padding: 13px 10px;
  background: #0080ff;
  color: #fff;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none; 
  cursor: pointer;
  border: none;
  border-radius: 4px;
} */

form.example .country_code:hover {
  background: #0080ff;
}

form.example::after {
  content: "";
  clear: both;
  display: table;
}
input.error {
  border: 1px solid #eadae4;
}

.error {
  color: #db2269;
  font-size: 0.625em;
  display: relative;
}
